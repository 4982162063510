const finlandConfig = {
  theme: 'evo-nordic',
  currency: '€',
  locales: {
    all: ['fi'],
    defaultLocale: 'fi'
  },
  tracking: {
    adtraction: {
      c: 'EUR',
      tp: '1233089508',
      ap: '1233089357'
    }
  }
}

export default finlandConfig
