import React from 'react'

import QuoteContext from '../../../components/Quote/context'

const withQuote = WrappedComponent => {
  const WithQuote = props => {
    return (
      <QuoteContext.Consumer>
        {quote => (
          <WrappedComponent quote={quote} {...props} />
        )}
      </QuoteContext.Consumer>
    )
  }

  return WithQuote
}

export { withQuote }
