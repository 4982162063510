import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

// Helper to generate year/month/day ranges
const range = (size, startAt = 0) => {
  return [...Array(size).keys()].map(i => i + startAt)
}

const dayOptions = (year, month) => {
  switch (month) {
    case '2':
      switch (year % 4) {
        case 0:
          return range(29, 1).map(day => <option key={day} value={day}>{day}</option>)
        default:
          return range(28, 1).map(day => <option key={day} value={day}>{day}</option>)
      }
    case '4':
    case '6':
    case '9':
    case '11':
      return range(30, 1).map(day => <option key={day} value={day}>{day}</option>)
    default:
      return range(31, 1).map(day => <option key={day} value={day}>{day}</option>)
  }
}

const Birthdate = ({ year, month, day, onChange, t, errors }) => {
  const birthdateError = errors.user && errors.user.birthdate

  const yearOptions = range(100, new Date().getFullYear() - 100).map(year =>
    <option key={year} value={year}>{year}</option>
  )

  const monthOptions = range(12, 1).map(month =>
    <option key={month} value={month}>{month}</option>
  )

  return (
    <div className='birthdate'>
      <label>{t('signup.profile.birthdate')}</label>
      <select className='birthdateSelect' name='birthdateYear' value={year} onChange={onChange} id='birthdate-year' autoComplete='bday-year'>
        {yearOptions}
      </select>
      <select className='birthdateSelect' name='birthdateMonth' value={month} onChange={onChange} id='birthdate-month' autoComplete='bday-month'>
        {monthOptions}
      </select>
      <select className='birthdateSelect' name='birthdateDay' value={day} onChange={onChange} id='birthdate-day' autoComplete='bday-day'>
        {dayOptions(year, month)}
      </select>
      {birthdateError && <Error messageKey='signup.profile.error.must_be_over_minimum_age' />}
    </div>
  )
}

export default translate()(Birthdate)
