const switzerlanqConfig = {
  operatorUUID: '4de2a7cb-bd28-4111-aa28-4bbcdf60f533',
  paymentMethods: ['credit_card'],
  mobilePrefix: '+41',
  theme: 'evo-hp',
  currency: 'CHF',
  locales: {
    all: ['de-CH', 'en-CH', 'fr-CH'],
    defaultLocale: 'fr-CH'
  },
  tracking: {
    gtm: {
      id: 'GTM-MP2M84P'
    },
    adtraction: {
      c: undefined,
      tp: undefined,
      ap: undefined
    }
  },
  termsURL:
    'https://evofitness.ch/de/allgemeine-mitgliedschaftsbedingungen/',
  dibs: {
    callbackURL:
      'https://dibs-api.production.credlock.net/4de2a7cb-bd28-4111-aa28-4bbcdf60f533/signup'
  },
  mypage: {
    url: 'https://me.evofitness.ch'
  }
}

export default switzerlanqConfig
