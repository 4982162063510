const austriaConfig = {
  operatorUUID: '051b413f-0245-4e84-9228-f3c7c5f8d299',
  paymentMethods: ['credit_card'],
  mobilePrefix: '+43',
  theme: 'evo-hp',
  currency: 'EUR',
  locales: {
    all: ['de-AT', 'en-AT'],
    defaultLocale: 'de-AT'
  },
  tracking: {
    gtm: {
      id: 'GTM-56W6SX2'
    },
    adtraction: {
      c: undefined,
      tp: undefined,
      ap: undefined
    }
  },
  termsURL:
    'https://evofitness.at/allgemeine-mitgliedschaftsbedingungen-von-evo/',
  dibs: {
    callbackURL:
      'https://dibs-api.production.credlock.net/051b413f-0245-4e84-9228-f3c7c5f8d299/signup'
  },
  mypage: {
    url: 'https://me.evofitness.at'
  }
}

export default austriaConfig
