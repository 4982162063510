import React from 'react'
import QuoteContext from './context'
import CurrentPayment from './components/CurrentPayment'
import NextPayment from './components/NextPayment'

const Quote = (props) => {
  return (
    <QuoteContext.Consumer>
      {quote => (
        <div className='quote'>
          {quote.currentPayment && quote.nextPayment &&
            <>
              {quote.presentation &&
                <section className='presentation'>
                  <img src='/strong.svg' alt='' />
                  <p style={{ whiteSpace: 'pre-line' }}>
                    {quote.presentation}
                  </p>
                </section>}
              <section className='charges'>
                <CurrentPayment payment={quote.currentPayment} />
                <NextPayment payment={quote.nextPayment} />
              </section>
            </>}
        </div>
      )}
    </QuoteContext.Consumer>
  )
}

export default Quote
