import { toCamelCase } from '../toCamelCase'

const MockAPI = {

  getTestOperatorUUID: function () {
    const request = new XMLHttpRequest()
    request.open('GET', 'https://api-gateway-signup-credlock.apps.redpill-linpro.com/v1/operators/test', false)
    request.send(null)

    if (request.status === 200) {
      return request.responseText
    }
  },

  getQuote: function (params, success) {
    success({
      status: 'payment_accepted',
      mypageAccessKey: 'secret123'
    })
  },

  makeSignupPayment: function (params) {
    console.log('Mock API.makeSignupPayment(params) invoked')
  },

  updateQuote: function (params, success) {
    success(toCamelCase({
      uuid: 'fe21f2a1-c438-4d71-82f0-17e556562fe6',
      begin_date: '2018-07-16',
      presentation: 'Quote presentation',
      current_payment: {
        total: 43429,
        date: '2018-07-16',
        lines: [
          {
            amount: 24900,
            product_type: 'membership',
            product_id: 1,
            system_concept: 'signup_fee',
            human_concept: null,
            period: null
          },
          {
            amount: 18529,
            product_type: 'membership',
            product_id: 1,
            system_concept: 'base_service_rest_of_month_fee',
            human_concept: null,
            period:
            {
              begin_date: '2018-07-16',
              end_date: '2018-07-31'
            }
          }
        ]
      },
      next_payment: {
        total: 35900,
        date: '2018-08-01',
        lines:
        [
          {
            amount: 35900,
            product_type: 'membership',
            product_id: 1,
            system_concept: 'base_service_rest_of_month_fee',
            human_concept: null,
            period: {
              begin_date: '2018-08-01',
              end_date: '2018-08-31'
            }
          }
        ]
      },
      validation_errors: {}
    }))
  },

  completeQuote: function (params, success, failure) {
    if (!params.user.first_name) {
      // fake a 422 and validation errors in body
      failure(422, toCamelCase({
        location_uuid: 'missing',
        code: 'invalid_promotion_or_referral_code',
        user: {
          first_name: 'missing',
          last_name: 'missing',
          gender: 'missing',
          birthdate: 'must_be_over_minimum_age',
          email: 'invalid_format',
          email_confirmation: 'missing',
          password: 'missing',
          payment_method: 'missing',
          address: {
            street: 'missing',
            postal_code: 'missing'
          },
          mobile: {
            number: 'invalid_format'
          },
          gdpr_opt_in: 'missing'
        }
      }))
      return
    }

    this.updateQuote(params, (quote) => {
      if (params.user.payment_method === 'credit_card') {
        // NOTE: Verifone Checkout ID most like expired
        const checkout = '4a88b47c-be2a-49cc-8d6c-c825d9f594bd'

        quote.payment_method = {
          type: 'credit_card',
          provider: 'verifone',
          details: {
            url: `https://cst.checkout.vficloud.net/v2/loader.js?checkoutId=${checkout}`,
            id: checkout
          }
        }
      } else if (params.user.payment_method === 'sepa') {
        quote.payment_method = {
          type: 'sepa',
          provider: 'slimpay',
          iframe: 'xyz'
        }
      } else {
        quote.payment_method = {
          type: 'invoice',
          provider: 'invoice'
        }
      }

      success(toCamelCase(quote))
    })
  },

  fetchLocations: function (params, success) {
    success([
      {
        name: 'Oslo og Akershus',
        id: '6dd67124-ffa9-472c-84d9-4dbccdb3c4bb',
        locations: [
          {
            id: '11cdcf9a-b620-4076-bb51-68b7d29d5aad',
            name: 'EVO Oscarsgate',
            isOpen: true
          },
          {
            id: '7c3a3b38-d027-49a1-928a-57da02908063',
            name: 'EVO Majorstua',
            isOpen: true
          }
        ]
      },
      {
        name: 'Vestfold',
        id: '2c6a9081-eb3e-4806-8336-2d55ecff3715',
        locations: [
          {
            id: '100b8c7a-850e-4ca9-a777-f317a4edfd41',
            name: 'EVO Larvik',
            isOpen: true
          },
          {
            id: 'a1a308fa-50c0-4f14-a775-d7d144850adb',
            name: 'EVO Sandefjord',
            isOpen: false
          }
        ]
      }
    ])
  },

  fetchPromotions: function (params, success) {
    success([
      {
        name: 'Basic promotion',
        code: 'ABC',
        locations: []
      },
      {
        name: 'Sandefjord Presale',
        code: 'DEF',
        locations: [
          {
            id: 'a1a308fa-50c0-4f14-a775-d7d144850adb',
            name: 'EVO Sandefjord',
            open_date: '2028-01-01'
          }
        ]
      }
    ])
  }
}

export default MockAPI
