import React from 'react'

import { withTranslation as translate } from 'react-i18next'

import Error from './Error'
import API from '../../../services/api'

class LocationSelect extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      allRegions: [],
      filteredRegions: [],
      activePromotions: []
    }
  }

  componentDidMount () {
    const params = { operator_id: this.props.operatorUUID }

    API.fetchPromotions(params, data => {
      this.setState({ activePromotions: data })
    })

    API.fetchLocations(params, data => {
      this.setState({ allRegions: data })
    })
  }

  // filter to include only open locations
  filterOpenLocations (data) {
    if (!data || data.length === 0) return []

    const regions = []
    data.forEach(r => {
      const open = r.locations.filter(l => l.isOpen)
      r.locations = open
      regions.push(r)
    })
    return regions
  }

  // filter to remove regions with no selected locations
  removeEmptyRegions (data) {
    if (!data || data.length === 0) return []

    return data.filter(r => r.locations.length > 0)
  }

  // filter to include only matched locations
  matchPromoLocations (data, locs) {
    if (!data || data.length === 0) return []

    const regions = []
    data.forEach(r => {
      const matched = r.locations.filter(l => locs.includes(l.id))
      r.locations = matched
      regions.push(r)
    })
    return regions
  }

  // if the promotion code is tied to certain locations we want to only have those locations
  //   available for selection, so create a filter of the appropriate location ids
  // otherwise we filter for open clubs only
  //  - ie user can't sign up to unopen club except with pre-sale promotions
  filterRegions (data) {
    let filteredRegions = JSON.parse(JSON.stringify(data))
    let locFilter = null

    if (filteredRegions.length === 0) return filteredRegions

    // this is a bit of a hack to get cypress test passing
    if (process.env.REACT_APP_ENV === 'ci') {
      return filteredRegions
    }

    if (this.state.activePromotions.length === 0) {
      filteredRegions = this.filterOpenLocations(filteredRegions)
      return this.removeEmptyRegions(filteredRegions)
    }

    // if promotion code is set
    if (this.props && this.props.code) {
      const promotion = this.state.activePromotions.find(ap => ap.code === this.props.code)

      if (promotion && promotion.locations && promotion.locations.length > 0) {
        locFilter = promotion.locations.map(elem => elem.id)
      }
    }

    if (locFilter && locFilter.length > 0) {
      // filter the data to only have the appropriate locations
      filteredRegions = this.matchPromoLocations(filteredRegions, locFilter)
    } else {
      // if we don't have promotion based locations, then filter for locations that are open
      filteredRegions = this.filterOpenLocations(filteredRegions)
    }

    // remove any regions that have no locations after filtering
    return this.removeEmptyRegions(filteredRegions)
  }

  render () {
    const { allRegions } = this.state
    const filteredRegions = this.filterRegions(allRegions)

    const regions = filteredRegions.map((region) => {
      const locations = region.locations.map((location) =>
        <option key={location.id} value={location.id}>{location.name}</option>
      )

      return (
        <optgroup label={region.name} key={region.id}>
          {locations}
        </optgroup>
      )
    })

    const { t, errors } = this.props

    return (
      <>
        <select className={`locationSelect ${errors.locationId ? 'error' : ''}`} value={this.props.value} onChange={this.props.onChange} id='location-select'>
          <option>{t('signup.club.title')}</option>
          {regions}
        </select>
        {errors.locationId && <Error messageKey='signup.profile.error.blank' />}
      </>
    )
  }
}

export default translate()(LocationSelect)
