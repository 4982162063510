import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const Password = ({ password, onChange, t, errors }) => {
  const passwordError = errors.user && errors.user.password

  return (
    <>
      <input
        className={passwordError ? 'error' : ''}
        name='password'
        type='password'
        value={password}
        placeholder={t('signup.profile.password')}
        onChange={onChange}
        autoComplete='new-password'
        id='password'
      />
      {passwordError && <Error messageKey='signup.profile.error.blank' />}
    </>
  )
}

export default translate()(Password)
