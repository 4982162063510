import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const Address = ({ street, postalCode, city, onChange, t, errors }) => {
  const streetError = errors.user && errors.user.address && errors.user.address.street
  const postalCodeError = errors.user && errors.user.address && errors.user.address.postalCode

  return (
    <div>
      <div>
        <input
          type='text'
          className={streetError ? 'error' : ''}
          name='street'
          value={street}
          onChange={onChange}
          placeholder={t('signup.profile.street')}
          id='street'
          autoComplete='street-address'
        />
        {streetError && <Error messageKey='signup.profile.error.blank' />}
      </div>
      <div>
        <input
          type='text'
          className={`code ${postalCodeError ? 'error' : ''}`}
          name='postalCode' value={postalCode}
          onChange={onChange}
          placeholder={t('signup.profile.postal_code')}
          id='postal-code'
          autoComplete='postal-code'
        />
        {postalCodeError && <Error messageKey='signup.profile.error.blank' />}

        <input
          type='text'
          name='city' value={city}
          onChange={onChange}
          placeholder={t('signup.profile.city')}
          id='city'
          autoComplete='address-level2'
        />
      </div>
    </div>
  )
}

export default translate()(Address)
