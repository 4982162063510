import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const PromotionOrReferrerCode = ({ value, onChange, t, errors }) => {
  // force codes to be uppercase when submitted
  const onFocusOut = (e) => {
    e.target.value = e.target.value.toUpperCase()
    // ensure change is added to state
    onChange(e)
  }

  return (
    <div>
      <input
        className={errors.code ? 'error' : ''}
        type='text'
        value={value}
        onChange={onChange} onBlur={onFocusOut}
        placeholder={t('signup.quote.product_code')}
      />
      {errors.code && <Error messageKey='signup.profile.error.service_code.invalid' />}
    </div>
  )
}

export default translate()(PromotionOrReferrerCode)
