import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const Gender = ({ value, onChange, t, errors }) => {
  const genderError = errors.user && errors.user.gender

  return (
    <div className='gender'>
      <div>
        <label htmlFor='female'>
          <input
            type='radio' name='gender' id='female' value='female'
            checked={value === 'female'} onChange={onChange}
          />
          {t('signup.profile.female')}
        </label>
      </div>
      <div>
        <label htmlFor='male'>
          <input
            type='radio' name='gender' id='male' value='male'
            checked={value === 'male'} onChange={onChange}
          />
          {t('signup.profile.male')}
        </label>
      </div>
      {genderError && <Error messageKey='signup.profile.error.blank' />}
    </div>
  )
}

export default translate()(Gender)
