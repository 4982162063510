import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const errorMessageMappings = {
  missing: 'signup.profile.error.blank',
  invalid: 'signup.profile.error.email.invalid',
  invalid_format: 'signup.profile.error.email.invalid',
  email_already_in_use: 'signup.profile.error.email.already_in_use'
}

const Email = ({ email, emailConfirmation, onChange, t, errors }) => {
  let emailError
  if (errors.user && errors.user.email) {
    emailError = <Error messageKey={errorMessageMappings[errors.user.email]} />
  }

  const emailConfirmationError = errors.user && errors.user.emailConfirmation

  // force emails to be lowercase when submitted
  const onFocusOut = (e) => {
    e.target.value = e.target.value.toLowerCase()
    // ensure change is added to state
    onChange(e)
  }

  return (
    <div>
      <div>
        <input
          name='email' className={emailError ? 'error' : ''} type='email'
          placeholder={t('signup.profile.email')} value={email || ''}
          onChange={onChange} onBlur={onFocusOut} id='email' autoComplete='email'
        />
        {emailError}
      </div>
      <div>
        <input
          name='emailConfirmation' type='email'
          placeholder={t('signup.profile.email_confirmation')}
          value={emailConfirmation} onChange={onChange} onBlur={onFocusOut}
          id='email-confirmation' autoComplete='email'
        />
        {emailConfirmationError && <Error messageKey='signup.profile.error.email.confirmation' />}
      </div>
    </div>
  )
}

export default translate()(Email)
