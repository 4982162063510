import React from 'react'
import { Helmet } from 'react-helmet'
import { withTranslation as translate } from 'react-i18next'
import Logo from './Logo'

const Layout = ({ theme, children, t }) => {
  const favicon = `/themes/${theme}/favicon.png`

  return (
    <>
      <Helmet>
        {
          // The theme name serves as a top level CSS class selector.
          // Effectively filters out all other theme styles.
        }
        <html class={theme} />

        <title>{t('signup.html_title')}</title>

        <link
          rel='shortcut icon'
          href={favicon}
          type='image/png'
        />
      </Helmet>

      <div className='container'>
        <div className='header'>
          <Logo theme={theme} />
        </div>
        {children}
      </div>
    </>
  )
}

export default translate()(Layout)
