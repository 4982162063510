import React from 'react'

import LocaleSwitcher from './LocaleSwitcher'

const Logo = ({ theme }) => {
  const path = `/themes/${theme}/logo.png`

  return (
    <header className='logo'>
      <img src={path} className='logo' alt='logo' />
      <LocaleSwitcher />
    </header>
  )
}

export default Logo
